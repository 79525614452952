{
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
	"id": 1,
	"name": "Grange Blanche (Lyon)"
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.876847408419823,
              45.74377809514207
            ],
            [
              4.876965688461479,
              45.74430337193658
            ],
            [
              4.879116234673398,
              45.74478362068072
            ],
            [
              4.88040656240055,
              45.7451813235447
            ],
            [
              4.881815170169356,
              45.7455039860506
            ],
            [
              4.883675392642665,
              45.74554150482561
            ],
            [
              4.887847452293787,
              45.745473971012466
            ],
            [
              4.888589390736899,
              45.740048487783454
            ],
            [
              4.885320560494782,
              45.74111411530886
            ],
            [
              4.88300872331697,
              45.741909570485674
            ],
            [
              4.883621628987367,
              45.740371179955666
            ],
            [
              4.882094741176906,
              45.7398083435612
            ],
            [
              4.88013774412406,
              45.7408589669082
            ],
            [
              4.879643118495318,
              45.7410991066117
            ],
            [
              4.876750633840288,
              45.74262246879711
            ],
            [
              4.876847408419823,
              45.74377809514207
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 2,
	"name": "Okeenea (Champagne-au-Mont-d'Or)"
      },
      "geometry": {
        "type": "Point",
	"coordinates": [4.779207, 45.802251]
      }
    }
  ]
}
